@import "../../colors.scss";

/* Position and sizing of burger button */

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $white;
  border-radius: 10px;
  max-height: 5px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $p-color-light;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 20px;
  width: 20px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $main-txt;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  display: block !important;
  z-index: 999 !important;
}

/* General sidebar styles */
.bm-menu {
  // background: $white;
  font-size: 1rem;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: Black;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
}

.bm-item-list .date-filter {
  flex-direction: column;
  padding-top: 0 !important;
  margin-top: 0 !important;
}
.bm-item-list label {
  margin: 0 !important;
  padding-top: 0 !important;
}
.bm-item-list .date-filter .date-wrapper {
  margin-right: 0 !important;
  padding-bottom: 0.5rem;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-bottom: 1rem;
}

/* Styling of overlay */
.bm-overlay {
  background: $overlay;
  z-index: 998 !important;
}
.burger-heading {
  outline: none;
}

// Custom css search
.filter-burger {
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
}

.filter-burger > div {
  position: relative;
  width: 100%;
  height: 100%;
}

.filter-burger__azSearch-btn {
  position: absolute;
  width: 50px;
  height: 38px;
  right: 15px;
  top: 12px;
  z-index: 999 !important;
}

.filter-burger__azSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.burger-btn {
  position: absolute;
  padding: 4px;
  right: 8px;
  top: 4px;
  border-radius: 4px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background-color: transparent;
  z-index: 998 !important;
  &:hover {
    background-color: $p-color-lighter;
  }
}

.qr-reader__burgerMenu__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;
  padding: 6px 0px;
}

.qr-reader__burgerMenu {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
}
.qr-reader__burgerMenu > div {
  position: relative;
  width: 100%;
  height: 100%;
  .bm-burger-button {
    margin-right: 14px !important;
    margin-top: 4px;
  }
}
.qr-reader__burgerMenu > div > div {
  z-index: 2;
  position: absolute;
  right: 0px;
  top: 0px;
}

.form-bruger-menu {
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
}

.form-bruger-menu > div {
  position: relative;
  width: 100%;
  height: 100%;
}

.form-bruger-menu__burger-btn{
    z-index: 1000 !important;
}

@media screen and (max-width: 576px) {
  .filter-burger__azSearch-btn {
    top: 8px;
  }
//   .form-bruger-menu {
//     display: block !important;
//     position: absolute;
//     height: 100%;
//     width: 100%;
//   }
//   .form-bruger-menu > div {
//     position: relative;
//     width: 100%;
//     height: 100%;
//   }
}
