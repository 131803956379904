@import "./components/colors.scss";

$test: darkgreen;
.App {
  max-height: 100vh;
  position: relative;
}

a {
  color: $main-txt;
}

///// MODALS //////////////////////////////////////////
#modal-root {
  z-index: 1101;
}

.modal-wrapper {
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: $overlay;
}

.modal-box {
  position: relative;
  max-width: 100%;
  // important due to show select outside modal
  overflow: visible;
  max-width: 800px;
  max-height: 100vh;
  position: relative;
  border-radius: 4px;
  background-color: white;
  z-index: 101;
  box-shadow: $bg-shadow-dark;
}

.modal-box-noClick {
  max-width: 800px;
  overflow-y: auto;
  position: relative;
  border-radius: 4px;
  z-index: 102;
  max-height: calc(100vh - 41px);
}

////////////////////////////////////////////////////////
///TOASTER /////////////////////////////////////////////

.toaster-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;
}

.toaster-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  // background-color: $overlay;
}

.toaster-box {
  max-width: 800px;
  max-height: 90vh;
  position: relative;
  overflow-y: auto;
  margin: 1rem;
  border-radius: 4px;
  background-color: white;
  z-index: 101;
  pointer-events: all;
  box-shadow: $bg-shadow-dark;
  animation: fadeIn ease 0.3s;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
  right: -200px;
}

.toaster-btn {
  position: absolute;
  top: 0;
  right: 0;
}

@-webkit-keyframes slide {
  100% {
    right: 0;
  }
}

@keyframes slide {
  100% {
    right: 0;
  }
}

//// RANDOM ////////////////////////
.drop-down-menu {
  position: absolute;
  min-width: 200px;
  z-index: 9;
  background-color: white;
  overflow: hidden;
  animation: fadeIn ease 0.3s;
  -webkit-animation: fadeIn ease 0.3s;
  -moz-animation: fadeIn ease 0.3s;
  -o-animation: fadeIn ease 0.3s;
  -ms-animation: fadeIn ease 0.3s;
}

.custom-heading:hover {
  background-color: $select-hover;
}
.filterIndex__option--is-focused .custom-heading {
  background-color: #82b6fb36 !important;
}
.custom-heading {
  background-color: $p-color-lightest;
}

.main-text-color {
  color: $p-color;
}
.menu-topbar-text {
  font-size: 14px;
}

.card-badge {
  padding: 5px 8px;
  letter-spacing: 0.5px;
  color: $white-txt;
  background-image: -webkit-linear-gradient(
    top,
    $p-color-light 0,
    $main-txt 100%
  );
  background-image: -o-linear-gradient(top, $p-color-light 0, $main-txt 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from($p-color-light),
    to($p-color)
  );
  background-image: linear-gradient(
    to bottom,
    $p-color-light 0,
    $main-txt 100%
  );
}

.card-badge-active {
  padding: 5px 8px;
  letter-spacing: 0.5px;
  color: $white-txt;
  background-color: $active-workstep;
}
//////////////////////////////////////////////

///LOADING SPINNER ////////////////////////////
.loading-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
  .loading-overlay-spinner {
    z-index: 1000;
  }
}
.spinner-border {
  color: $p-color;
}

////////////////////////////////////////////////////

//// SCROLLBAR ////////////////////////////////////
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: hsla(0, 1%, 43%, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7ca6ca;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #70b3ee;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: hsla(0, 1%, 43%, 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #7ca6ca;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #70b3ee;
}
//////////////////////////////////////////////////

// SPLITTER  ///////////////////////////////////////
.arrow-right {
  background-color: white;
  border-radius: 0 50% 50% 0;
  box-shadow: 0px 0px 5px 2px rgba(58, 58, 58, 0.301);
  &:hover {
    background: #e1eefa;
    transition: all 0.5s ease;
    text-decoration: none;
  }
}
.Resizer {
  // background: #000;
  opacity: 1;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 0.5s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
  &:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.3);
    border-bottom: 5px solid rgba(0, 0, 0, 0.3);
  }
}

.Resizer.vertical {
  width: 9px;
  background-color: white;
  box-shadow: 0px 0px 5px 2px rgba(58, 58, 58, 0.301);
  -webkit-box-shadow: 0px 0px 5px 2px rgba(58, 58, 58, 0.301);
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  cursor: col-resize;
  &:hover {
    background-color: rgb(238, 238, 238);
  }
}

.SplitPane {
  max-width: 100;
}

.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.Pane2 {
  overflow: auto;
}

.notInteractive {
  pointer-events: none;
}

.isInteractive {
  pointer-events: auto;
}

/////////////////////////////////////////////////

//POPPER ARROW STYLING

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}

.arrow::before {
  content: "";
  transform: rotate(45deg);
  background: $gray-darker;
  width: 8px;
  height: 8px;
}

.arrow-light,
.arrow-light::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}
.arrow-light::before {
  content: "";
  transform: rotate(45deg);
  background: $white;
  width: 8px;
  height: 8px;
}

.arrow-error,
.arrow-error::before {
  position: absolute;
  width: 8px;
  height: 8px;
  z-index: -1;
}

.arrow-error::before {
  content: "";
  transform: rotate(45deg);
  background: mistyrose;
  width: 8px;
  height: 8px;
}

.tool-tip {
  z-index: 9999999;
  box-shadow: $bg-shadow-dark;
  word-break: break-word;
}
.tool-tip[data-popper-placement^="top"] > .arrow {
  bottom: -4px;
}

.tool-tip[data-popper-placement^="bottom"] > .arrow {
  top: -4px;
}

.tool-tip[data-popper-placement^="left"] > .arrow {
  right: -4px;
}

.tool-tip[data-popper-placement^="right"] > .arrow {
  left: -4px;
}
.tool-tip[data-popper-placement^="top"] > .arrow-light {
  bottom: -4px;
}

.tool-tip[data-popper-placement^="bottom"] > .arrow-light {
  top: -4px;
}

.tool-tip[data-popper-placement^="left"] > .arrow-light {
  right: -4px;
}

.tool-tip[data-popper-placement^="right"] > .arrow-light {
  left: -4px;
}

.tool-tip[data-popper-placement^="top"] > .arrow-error {
  bottom: -4px;
}

.tool-tip[data-popper-placement^="bottom"] > .arrow-error {
  top: -4px;
}

.tool-tip[data-popper-placement^="left"] > .arrow-error {
  right: -4px;
}

.tool-tip[data-popper-placement^="right"] > .arrow-error {
  left: -4px;
}

///////////////////////////////
/////////INFO-BOX//////////////

.info-box {
  background-color: $infoBox;
  border: 1px solid $border;
}

///////////////////////////

.underline-input {
  background: transparent;
  border: none;
  border-bottom: 1px solid #ced4da;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  // padding-left: 0.25rem;
  display: flex;
  justify-content: center;
  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 0 0 2px 0;
  }
}

////////////////////////////
iframe > body {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: hsla(0, 1%, 43%, 0.1);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #7ca6ca;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #70b3ee;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: hsla(0, 1%, 43%, 0.1);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #7ca6ca;
    border-radius: 4px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #70b3ee;
  }
}

code {
  background-color: $mediumgray;
  border-radius: 2px;
  padding: 0.25rem 0.25rem;
  color: black;
}

.topbar {
  box-shadow: $bg-shadow;
  background-color: $white;
  z-index: 10;
}

////////////////////////////////
////////// CUSTOM FORM PROPERTIES
.disabled-form-control {
  display: flex;
  flex-wrap: wrap;
  min-height: 38px;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-width: 222px;
}

.fake-form-control {
  display: flex;
  flex-wrap: wrap;
  min-height: 38px;
  width: 100%;
  padding: 0rem 0.25rem 0.25rem 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  // min-width: 222px;
}

.customLink {
  margin-top: 0.25rem;
  border-radius: 2px;
  font-size: $font-size-14;
  color: #337ab7;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
  margin-right: 0.25rem !important;
  align-items: center !important;
  display: flex !important;
  background-color: #f8f9fa !important;
}

.agreement-label {
  @include labels;
}

.btn-info_custom {
  border: 1px solid transparent;
  color: $main-txt;
  border-radius: 4px;
  background-color: transparent;
  &:hover {
    border: 1px solid lightgray;
  }
}

.react-loading-skeleton {
  line-height: normal !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.workstep-title {
  background-color: $activestep;
  background: $activestep;
  color: $white;
  font-size: $font-size-16;
  &:focus {
    color: $white;
  }
}
.workstep-title__btn {
  background-color: $activestep;
  background: $activestep;
  color: $white;
  font-size: $font-size-16;
  &:hover {
    color: $white;
    background-color: $activestep_hover;
  }
  &:focus {
    color: $white;
  }
}



// AgFormModal
.ag-form-modal{
  max-width: $form-maxWidth;
  width: -webkit-fill-available;
}

// WORKSTEP BUTTON COLORS

.workstep-title__btn {
  background-color: $activestep;
  background: $activestep;
  color: $white;
  font-size: $font-size-16;
  &:hover {
    color: $white;
    background-color: $activestep_hover;
  }
  &:focus {
    color: $white;
  }
}

////////////////////////////////////////////////////////////////////////
////////// ACCORDION //////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////
.accordion__icon {
  transform: rotate(0);
  transition: 0.4s ease;
}
.rotate {
  transform: rotate(180deg);
  transition: 0.4s ease;
}

.disabled-overlay {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0.25rem;
  border: 1px solid $border;
  cursor: not-allowed;
  width: calc(100% + 2px);
  margin: -1px;
  height: calc(100% + 2px);
  background-color: $disabled-overlay;
}

// Remove standard black focus line
*:focus {
  outline: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 520px) {
  .modal-box-noClick {
    max-width: 95%;
  }
  .modal-box {
    max-height: 100vh !important;
    max-width: 100% !important;
    // margin: 12px 0;
  }
}
