@import "./components/colors.scss";

body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-family: Nunito, sans-serif;
  position: relative;
  margin: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  &:hover {
    border-color: hsl(0, 0%, 70%) !important;
  }
}

textarea {
  &:hover {
    border-color: hsl(0, 0%, 70%) !important;
  }
}

// Override default bootstrap variables before the import
$primary: $main-txt;
$font-size-base: 0.875rem;
.btn {
  font-size: $font-size-16 !important;
}

.btn-lg {
  font-size: $font-size-20 !important;
}

.btn-sm {
  font-size: $font-size-14 !important;
}
.btn-xs {
  font-size: $font-size-12 !important;
}

// no margin bottom as default
#bootstrap-overrides p {
  margin-bottom: 0px;
}

// .badge {
//   font-size: 85% !important;
// }

.form-control {
  min-height: 38px !important;
}

small {
  font-size: $font-size-14 !important;
}

.input-group-text{
  text-align: start !important;
  white-space: normal !important;
} 

.input-group-prepend {
   margin-right: 0px !important;
}

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
