// TEXT colors

$main-txt: #337ab7;
$black-txt: black;
$white-txt: white;

// APP colors

$p-color: #337ab7;
$p-color-light: #62aef0;
$p-color-lighter: #deebff;
$p-color-lightest: #ebf8fa;

$white: white;
$white-darker: #f7f7f7;
$lightgray: lightgray;
$mediumgray: #e6e6e6;
$gray: gray;
$gray-darker: #6c757d;
$gray-light: hsl(0, 0%, 90%);

$input-focus: #80bdff;
$select-hover: #82b7fb;
$select-focus: #2576f0;
$select-focusOpt: #deebff;
$overlay: rgba(0, 0, 0, 0.3);
$disabled-overlay: rgba(127, 128, 129, 0.3);
$disabledColor: #e9ecef;

// WORKSTEP
$active-workstep: #5bc0de;
$published-workstep: #5cb85c;

//BACKGROUND COLORS
$lightgreen: #e5ffe5;
$lightblue: rgb(235, 248, 250);
$lightyellow: #fffff3;
$activestep: #5bc0de;
$activestep_hover: #5a9eb3;
$infoBox: rgb(238, 238, 238);

// BORDER Colors
$border: #ced4da;
$border-accordian: #dee2e6;
// BUTTON colors
$p-btn: #17a2b8;
$f-btn-hover: #86d3df;
$p-btn-hover: #138496;

// DND
$active-dropzone: #caffca;

// Shadows
$bg-shadow: 0 4px 2px -2px rgba(58, 58, 58, 0.301);
$bg-shadow-border: 0 0 0 1px hsla(0, 1%, 43%, 0.1),
  0 4px 11px hsla(0, 0%, 0%, 0.1);
$bg-shadow-dark: 0 4px 11px hsla(0, 0%, 0%, 0.3);

$font-size-16: 1rem;
$font-size-14: 0.875rem;
$font-size-12: 0.75rem;
$font-size-20: 1.25rem;

// BOOTSTAP
$bg-light: #f8f9fa;

// Forms
$form-maxWidth: 1200px;

@mixin labels {
  padding-left: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  margin-bottom: 0rem;
  color: $gray-darker;
  text-transform: uppercase;
}

@mixin select-shadow {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
}
